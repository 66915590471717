import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is The Purpose Of A Resume?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The main purpose of a resume is to get a job interview, structure the interview process and serve as the basis for justifying the hiring decision."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Do I Need More Than One Version Of My Resume?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "You may need to consider creating more than one version of your resume because more than one organizational format for your resume is essential. You may also need more than one delivery format too. Codestaff is capable of delivering resume that stands out."
        }
    }
}

const ResumeDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Resume Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Resume Developers. Top
                                    companies and start-ups choose Codestaff professional Resume
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Resume Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE RESUME EXPERTS' banner='/resume-developer.png' bannerAlt='resume developer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default ResumeDeveloper