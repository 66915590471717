import React from 'react'
import Layout from '../components/layout'
import ResumeDeveloper from '../components/categories/resume-developer'
import Seo from '../components/seo'

const ResumeDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ResumeDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Resume Developer Freelancers | Codestaff'
  const description =
    'Hire the best Resume Developer freelancers at Codestaff. Get the top 1% of Resume Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ResumeDeveloperPage
